/* import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* Variables */
:root {
    --accent-1: #fafafa;
    --accent-2: #eaeaea;
    --accent-3: #333;
    --nav-height: 3rem;
    --nav-feature-dimension: 1.875rem;
    --nav-feature-padding: 0.625rem;
    --black-line-height: .5vh;
}

html {
    font-size: 13pt;
    scroll-behavior: smooth;
}

/* Content */
body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5rem;
    padding-right: 15rem;
    padding-left: 15rem;
    font-family: 'Inter', sans-serif; /* Added font-family */
    max-width: 50rem;
}

header {
    display: block;
    padding: 1rem;
    width: 100%;
    text-align: left;
}

section {
    padding: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem; /* This adds some space between the columns */
}

/* Navigation Bar */
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    font: 1em;
    height: var(--nav-height);
    display: flex;
    justify-content: flex-start; /* This will left-align the nav menu */
    align-items: center;
    background-color: #000;
    transition: height .1s;
}

.nav:hover .hamburger-menu {
    opacity: 1; /* Adjust this value as needed */
}


.nav-menu {
    position: fixed;
    top: var(--nav-height);
    right: 0;
    width: 10rem;
    height: 100%;
    background: #fff;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.2s;
}

.nav-menu a {
    display: block;
    padding: 1rem;
    text-decoration: none;
    color: #000;
    transition: background-color 0.2s;
}

.nav-menu a:hover {
    color: #575757;
}

.nav-menu.show {
    transform: translateX(0);
}

.line-wrapper {
    position: fixed;
    top: var(--nav-height);
    left: 0px;
    width: 100%;
}
.black-line {
    height: calc(var(--nav-height) * 1.618);
    border-bottom: var(--black-line-height) solid #000;
    background-color: white;  
    display: flex;
    justify-content: left;
    align-items: center;
}

.white-line {
    height: calc(var(--black-line-height) * 1.618);
    background-color: white;
}

main {
    border-left: 1px solid #000;
}


.logo-container {
    /* position: absolute; */
    padding-left: var(--nav-feature-padding);
    opacity: 0;
    color: #000;
    transition: opacity .1s; /* This creates a fade effect */
}

.show-logo {
    opacity: 1;
}


/* Heading Styling */
h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 4.5rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 2rem;
}

h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 3.5rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 1.5rem;
    text-decoration: none;
}

h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 2.75rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 1rem;
}

h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 2.25rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 0.5rem;
}

h5 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.75rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 0.25rem;
}

.mailto {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.75rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 0.25rem;
}

.mailto:hover {
    text-decoration: none;
    color: #5b5b5b
}

.map_link {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 0.25rem;
    border-bottom: 1px solid black;
    text-decoration: none;
}

.map_link a {
    text-decoration: none;
    color: black
}
    
.map_link a:hover {
    border-bottom: none;
    color: #5b5b5b
}

.stripe_link a {
    text-decoration: none;
    color: black
}

.stripe_link:hover {
  border-bottom: none;
  color: #5b5b5b
}

.stripe_link {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  color: var(--accent-3);
  letter-spacing: -0.04em;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid black;
  text-decoration: none;
}

.internal_link a {
    text-decoration: none;
    color: black
}

.internal_link:hover {
  border-bottom: none;
  color: #5b5b5b
}

.internal_link:visited {
  color: var(--accent-3);
}

.internal_link {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  color: var(--accent-3);
  letter-spacing: -0.04em;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid black;
  text-decoration: none;
}

line {
    border-bottom: 1px solid black;
}

.subtext {
    font-style: italic;
}

.testimonial {
  text-indent: var(--nav-height);
}

.ticket-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .ticket-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .ticket-name, .ticket-email {
    flex: 1;
  }
  
  .odd {
    background-color: #282c34;
    color: white;
  }
  
  .even {
    background-color: #61dafb;
    color: black;
  }

h6 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 0.25rem;
}

h2 a {
    color: var(--accent-3);
    text-decoration: none;
}

/* Paragraph Styling */
p {
    font-family: 'Inter', sans-serif; /* Added font-family */
    font-weight: 400;
    font-size: 1rem;
    color: var(--accent-3);
    letter-spacing: -0.04em;
    margin-bottom: 1rem;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: var(--nav-feature-dimension); 
    height: var(--nav-feature-dimension); 
    position: fixed;
    top: 0.625rem;
    right: 0.625rem;
    z-index: 100;
    cursor: pointer;
    opacity: .2;
    transition: all .1s;
}

.hamburger-menu:hover {
    opacity: 1;
}


.hamburger-menu div {
    width: 100%;
    height: 0.25rem; 
    background-color: #797979;
    transition: all .2s;
}

.hamburger-menu div a {
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    transition: opacity .4s, visibility 0s;
}

.hamburger-menu.expanded {
    rotate: 90deg; 
}


.img:hover {
    opacity: 0.5;
}

.hamburger-menu.expanded div a {
    opacity: 1;
    display: flex;
    visibility: visible;
    color: #5b5b5b;
    text-decoration: none;
    text-align: center;
    transition: opacity .1s, visibility 0s;
    margin-left: .875rem;
}

.hamburger-menu.expanded div a:hover {
    color: #000;
}


/* Add media query for smaller screens */
@media (max-width: 768px),
        (min-resolution: 192dpi) and (max-width: 1080px),
        (min-resolution: 288dpi) and (max-width: 1080px)
 {
    html {
        font-size: 3vh;
    }

    body {
        flex-direction: column;
        align-items: center;
        padding-top: 3rem;
        padding-right: 1rem;
        padding-left: 1rem;
        /* max-width: 50rem; */
    }

    header {
        /* padding-top: 2vh; */
    }

    .hero {
        padding-top: 4rem;
        margin: 0;
        font-size: 1vh;
        align-items: center;
    }

    .hero-header {
        font-size: 8vh;
        margin-bottom: 0;
    }

    #logo h2 {
        /* font-size: calc(var(--nav-height)); */
        font-size: 11vw;
    }

    main {
        border-left: none;
        width: 100%;
    }

    section {
        padding: 0;
    }

    .grid-container {
        grid-template-columns: 1fr; /* This creates one column on smaller screens */
        gap: 0;
    }
    
    .hamburger-menu {
        display: flex;
        opacity: 1;
    }

    .nav-menu {
        width: 50%;
        height: 100%;
        font-size: 2.5vh;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 2.5rem;
    }
    
    
    p {
      font-family: 'Inter', sans-serif; /* Added font-family */
      font-weight: 400;
      /* font-size: 1.6rem; */
      color: var(--accent-3);
      letter-spacing: -0.04em;
      margin-bottom: 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
        padding: 0;
    }

}
