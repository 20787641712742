.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.swing-image {
    width: 80%;
    animation: swing 3s ease-out infinite;
}

/* create an animation that eases from position to position and stops at 90 degree increments */

@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}





p {
    margin-top: 10px;
    font-size: 16px;
    color: #000;
}